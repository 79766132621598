import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const inviewModule = (props) => {

    const inviewFadeY = document.querySelectorAll('.js-inview-fade-y')

    inviewFadeY.forEach((element) => {

        let delay = element.getAttribute('data-delay') ? element.getAttribute('data-delay') : 0.1;
        let percent = element.getAttribute('data-percent') ? element.getAttribute('data-percent') : 5;

        gsap.fromTo(element, {
            yPercent: percent,
            opacity: 0,
            delay: delay,
            duration: 0.8,
            ease: "power2.inOut",
            scrollTrigger: {
                trigger: element,
                start: 'top 95%',
            }
        }, {
            yPercent: 0,
            opacity: 1,
            duration: 0.8,
            ease: "power2.inOut",
            scrollTrigger: {
                trigger: element,
                start: 'top 95%',
            }
        });

    });

}
   
export { inviewModule };