import barba from '@barba/core';
import htmx from 'htmx.org';
import VanillaTilt from 'vanilla-tilt';
import { barbaTransitionModule } from './barbaTransition';

const barbaModule = () => {
    
    barba.init({
        transitions: [
            {
                sync: true,
                leave: ({ current }) => barbaTransitionModule(current.container, 'leave', 'next'),
                enter: ({ next }) => barbaTransitionModule(next.container, 'enter', 'next'),
            },
            {
                sync: true,
                custom: ({ trigger }) => trigger.dataset && trigger.dataset.direction === 'prev',
                leave: ({ current }) => barbaTransitionModule(current.container, 'leave', 'prev'),
                enter: ({ next }) => barbaTransitionModule(next.container, 'enter', 'prev'),
            },
        ],
    });

    // Hooks

    barba.hooks.beforeEnter(({ next }) => {
        
        htmx.process(next.container);
        document.body.style.cursor = 'default';

    });
    
    barba.hooks.before(() => {
        barba.wrapper.classList.add('is-animating');
    });
    
    barba.hooks.after(() => {

        barba.wrapper.classList.remove('is-animating');
        barba.wrapper.querySelector('main').removeAttribute('style');
        VanillaTilt.init(document.querySelectorAll('[data-tilt]'));

    });

}

export { barbaModule };