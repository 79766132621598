import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import Swiper from 'swiper';
import { Autoplay, EffectFade, Pagination, Navigation } from 'swiper/modules';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import VanillaTilt from 'vanilla-tilt';
import Cookies from 'js-cookie'

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

Swiper.use([Autoplay, EffectFade, Pagination, Navigation]);

// Components

import { inviewModule } from './modules/inview';
import { barbaModule } from './modules/barba';

// Exports

inviewModule();
barbaModule();

// Global
window.Swiper = Swiper;
window.Alpine = Alpine;
window.VanillaTilt = VanillaTilt;
window.Cookies = Cookies;

// GSAP
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
window.gsap = gsap;
window.ScrollTrigger = ScrollTrigger;
window.ScrollToPlugin = ScrollToPlugin;

// Inits
Alpine.plugin(collapse);
Alpine.start();

// External Links
document.querySelectorAll('a').forEach((link) => {
    if (link.hostname !== window.location.hostname) {
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
    }
});
